<template>
	<div id="gridPrincipalVendas">
		<div id="conteudo">
			<div class="col w-30 center container">
				<div class="inputs">					
					<div class="row" v-if="tipo=='v'">			
						<div class="input-group">
							<select v-model="tipo">
								<option value="v">Valor</option>
								<option value="p">Promoção</option>
							</select>
							<label>Tipo</label>
						</div>			
						<div class="input-group">
							<input type="text" id="descricao" class="mx-5 w-100" v-model="valor.prazo"
								autocomplete="off"
								placeholder=" "
								@input="alteraValor('prazo');alteraParcela()"
								v-mascaraFloat>
							<label for="descricao">$ Prazo</label>
						</div>
						<div class="input-group">
							<input type="text" id="descricao" class="mx-5 w-100" v-model="valor.desconto"
								autocomplete="off"
								placeholder=" "
								@input="alteraValor('desconto')"
								v-mascaraFloat>
							<label for="descricao">% Desconto</label>
						</div>
						<div class="input-group">
							<input type="text" id="descricao" class="mx-5 w-100" v-model="valor.vista"
								autocomplete="off"
								placeholder=" "
								@input="alteraValor('vista')"
								v-mascaraFloat>
							<label for="descricao">$ Vista</label>
						</div>
						<div class="input-group">
							<input type="number" id="qtdParcela" class="mx-5 w-100" v-model="valor.qtdParcela"
								autocomplete="off"
								v-mascaraInteger
								@input="alteraParcela()"
								placeholder=" ">
							<label for="qtdParcela">Qtd. Parcelas</label>
						</div>
						<div class="input-group">
							<input type="text" id="descricao" class="mx-5 w-100" v-model="valor.parcela"
								autocomplete="off"
								placeholder=" "
								v-mascaraFloat>
							<label for="descricao">$ Parcela</label>
						</div>
					</div>
					<div class="row" v-if="tipo=='p'">
						<div class="input-group">
							<select v-model="tipo">
								<option value="v">Valor</option>
								<option value="p">Promoção</option>
							</select>
							<label>Tipo</label>
						</div>	
						<div class="input-group">
							<input type="text" id="descricao" class="mx-5 w-100" v-model="promocao.de"
								autocomplete="off"
								placeholder=" "
								@input="alteraPromocao('de')"
								v-mascaraFloat>
							<label for="descricao">De $:</label>
						</div>
						<div class="input-group">
							<input type="text" id="descricao" class="mx-5 w-100" v-model="promocao.desconto"
								autocomplete="off"
								placeholder=" "
								@input="alteraPromocao('desconto')"
								v-mascaraFloat>
							<label for="descricao">% Desconto</label>
						</div>
						<div class="input-group">
							<input type="text" id="descricao" class="mx-5 w-100" v-model="promocao.por"
								autocomplete="off"
								placeholder=" "
								@input="alteraPromocao('por')"
								v-mascaraFloat>
							<label for="descricao">Por $:</label>
						</div>
					</div>
					<div class="row">
						<div class="input-group">
							<input type="text" id="descricao" class="mx-5 w-100" v-model="descricao"
								autocomplete="off"
								placeholder=" ">
							<label for="descricao">Descrição</label>
						</div>
						<div class="input-group">
							<input type="number" id="qtdEtiqueta" class="mx-5 w-100" v-model="qtdEtiqueta"
								autocomplete="off"
								v-mascaraInteger
								placeholder=" ">
							<label for="qtdEtiqueta">Qtd. Etiquetas</label>
						</div>
											
						<button class="botao botao-acao" @click="gerarEtiquetas">Gerar Etiquetas</button>
					</div>
				</div>
				<div class="tabelas">
					<div class="tabela">
						<table v-if="EtiquetasValor.length > 0">
							<thead>
								<tr>
									<td colspan="2">Valor</td>
									<td colspan="2">
										Limpar
										<i class="fas fa-trash"
										style="color: var(--deletar)"
										@click="limpaTabela('v')"></i>
									</td>
								</tr>
								<tr>
									<td>Descrição</td>
									<td>Prazo</td>
									<td>Vezes</td>
									<td>Parcela</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="etq in EtiquetasValor" :key="etq.etiqueta.descricao">
									<td class="text-left">{{ etq.etiqueta.descricao }}</td>
									<td class="text-left">{{ etq.etiqueta.prazo }}</td>
									<td class="text-center">{{ etq.etiqueta.qtdParcela }}</td>
									<td class="text-left">{{ etq.etiqueta.parcela }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="tabela">
						<table v-if="EtiquetasPromocao.length>0">
							<thead>
								<tr>
									<td>Promoção</td>
									<td colspan="2">
										Limpar
										<i class="fas fa-trash"
										style="color: var(--deletar)"
										@click="limpaTabela('p')"></i>
									</td>
								</tr>
								<tr>
									<td>Descrição</td>
									<td>De</td>
									<td>Por</td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="etq in EtiquetasPromocao" :key="etq.etiqueta.descricao">
									<td class="text-left">{{ etq.etiqueta.descricao }}</td>
									<td class="text-left">{{ etq.etiqueta.de }}</td>
									<td class="text-left">{{ etq.etiqueta.por }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<button class="botao botao-acao" @click="imprimeEtiqueta">Imprimir Etiquetas</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EtiquetaVitrine",

	components: {
	},

	data() {
		return {
			tipo:"v",
			descricao:"",
			qtdEtiqueta: 1,
			valor:{
				prazo:0,
				vista:0,
				desconto:0,
				qtdParcela:0,
				parcela:0,
			},
			promocao:{
				de:0,
				por:0,
				desconto:0,
			},
			etiquetas:[]
		};
	},

	computed: {
		EtiquetasPromocao(){
			return this.etiquetas.filter((etiqueta)=>{return etiqueta.tipo == "p";});
		},
		EtiquetasValor(){
			return this.etiquetas.filter((etiqueta)=>{return etiqueta.tipo == "v";});
		}
	},
	methods: {
		limpaTabela(tipo){
			const tamanho = this.etiquetas.length-1;
			for(let i = tamanho; i>-1;i--){
				if(this.etiquetas[i].tipo == tipo){
					this.etiquetas.splice(i,1);
				}
			}
		},
		alteraValor(campo){
			if(campo != "vista"){
				this.valor.vista = this.valor.prazo.replace(",",".")-(this.valor.prazo.replace(",",".")*(this.valor.desconto/100));
				this.valor.vista = this.valor.vista.toFixed(2);
			}
			else{
				console.log((this.valor.vista.replace(",",".")||0),(this.valor.prazo.replace(",",".")||0));
				this.valor.desconto = (100-(((this.valor.vista.replace(",",".")||1)*100)/(this.valor.prazo.replace(",",".")||1))).toFixed(2);
			}
		},
		alteraPromocao(campo){
			if(campo != "por"){
				this.promocao.por = this.promocao.de.replace(",",".")-(this.promocao.de.replace(",",".")*(this.promocao.desconto/100));
				this.promocao.por = this.promocao.por.toFixed(2);
			}
			else{
				this.promocao.desconto = (100-(((this.promocao.por.replace(",",".")||1)*100)/(this.promocao.de.replace(",",".")||1))).toFixed(2);
			}
		},
		alteraParcela(){
			this.valor.parcela = this.valor.qtdParcela < 1?0 : (this.valor.prazo.replace(",",".")/this.valor.qtdParcela).toFixed(2);
		},
		gerarEtiquetas(){
			for(let i = 0; i<this.qtdEtiqueta; i++){
				let etiqueta={
					descricao: this.descricao,
				};
				if(this.tipo == "v"){
					etiqueta.prazo = Number(this.valor.prazo.replace(",","."));
					etiqueta.vista = Number(this.valor.vista.replace(",","."));
					etiqueta.desconto = this.valor.desconto;
					etiqueta.qtdParcela = this.valor.qtdParcela;
					etiqueta.parcela = Number(this.valor.parcela.replace(",","."));
				}
				if(this.tipo == "p"){
					etiqueta.de = this.promocao.de;
					etiqueta.por = this.promocao.por;
					etiqueta.desconto = this.promocao.desconto;
				}
				this.etiquetas.push(
					{tipo:this.tipo,etiqueta:etiqueta}
				);
			}
		},
		imprimeEtiqueta(){
			let payloadValor = {etiquetas:[]};
			for(const etq of this.EtiquetasValor){
				payloadValor.etiquetas.push(etq.etiqueta);
			}
			if(payloadValor.etiquetas.length > 0) this.$store.dispatch("gerarEtiquetaVitrineValor", payloadValor );
			let payloadPromoacao = {etiquetas:[]};
			for(const etq of this.EtiquetasPromocao){
				payloadPromoacao.etiquetas.push(etq.etiqueta);
			}
			if(payloadPromoacao.etiquetas.length > 0) this.$store.dispatch("gerarEtiquetaVitrinePromocao", payloadPromoacao );
		},
	},
};
</script>

<style scoped>
.tabelas{
	display:flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	height: 100%;
	width: 100%;
	overflow: auto;
	margin: 10px;
}
.tabela{
	height: 100%;
}
.inputs{
	width: 100%;
}
.row .input-group{
	width: 100%;
}
.row{
	margin-top: 10px;
}
.container {
    margin: 0px auto;
    border-radius: 10px;
    height: 90%;
    width: 90%;
    background-color: var(--bg-app);
    box-shadow: var(--shadow-primario);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
}

#gridPrincipalVendas {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-template-areas:
		"conteudo";
	background-color: var(--bg-app);
	padding-top: 5px;
	height: 100%;
	max-height: 92vh;
	/* min-height: 530px; */
	width: 100%;
}
#conteudo {
	grid-area: conteudo;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	margin: 0px auto;
	border-top-left-radius: 3%;
	border-top-right-radius: 3%;
	background-color: var(--bg-secundario);
	overflow: hidden;
}

@media screen and (max-width: 1040px) {
	#gridPrincipalVendas {
		grid-template-rows: 110px auto;
	}
}

@media screen and (max-width: 768px) {
	#gridPrincipalVendas {
		grid-template-rows: 40px auto;
	}
}
</style>